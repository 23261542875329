import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { useRouter } from 'next/router';
import Button from '../../../../UI/Button/Button';
import styles from './EmailModal.module.scss';
import EmailActionIcon from '../../../../../../public/icons/svg/EmailActionIcon.svg';
import XIcon from '../../../../../../public/icons/svg/xIcon.svg';
import firebase from '../../../../../firebase';
import LoadingSpinner from '../../../../UI/LoadingSpinner/LoadingSpinner';

const EmailModal = ({ redirect, onClose }) => {
  const [email, setEmail] = useState();
  const router = useRouter();
  const [sending, setSending] = useState(false);
  const actionCodeSettings = {
    url: `${window.location.origin}/email-link`,
    // This must be true.
    handleCodeInApp: true,
  };

  if (redirect) {
    actionCodeSettings.url = `${actionCodeSettings.url}?red=${redirect}`;
  }

  const handleSignUpWithEmail = async () => {
    if (email) {
      setSending(true);
      firebase
        .auth()
        .sendSignInLinkToEmail(email, actionCodeSettings)
        .then(() => {
          window.localStorage.setItem('emailForSignIn', email);
          setSending(false);
          onClose();
          router.push('/');
          toast.success('Email link successfully sent. Check your inbox for the link to sign up.');
        })
        .catch((error) => {
          toast.error(`Request failed: ${error.message}`);
          setSending(false);
          onClose();
        });
    }
  };
  return (
    <div className={styles.wrapper}>
      <button type="button" onClick={onClose} className={styles.closeIcon}>
        <XIcon />
      </button>
      <div className={styles.images}>
        <EmailActionIcon />
      </div>
      <p className={styles.text}>Login or Sign Up with your Email</p>
      <input
        id="email"
        type="emali"
        name="email"
        placeholder="your@email.com"
        className={styles.email_input}
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <hr className={styles.divider} />
      <div className={styles.btnWrapper}>
        <button type="button" className={styles.cancelBtn} onClick={onClose}>Cancel</button>
        {sending ? <LoadingSpinner size="small" />
          : <Button shorter action={handleSignUpWithEmail}>Login / Sign Up</Button>}
      </div>
    </div>
  );
};

EmailModal.propTypes = {
  redirect: PropTypes.string,
  onClose: PropTypes.func,
};

EmailModal.defaultProps = {
  redirect: null,
  onClose: undefined,
};

export default EmailModal;
