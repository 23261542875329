/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import clsx from 'clsx';
import { useRouter } from 'next/router';
import styles from './NewAuth.module.scss';
import GoogleIcon from './googleIcon.svg';
import WalletIcon from './walletIcon.svg';
import EmailIcon from './emailIcon.svg';
import AppleIcon from './appleIcon.svg';
import {
  appleLogin,
  googleLogin,
  guestLogin,
  resetRedirectState,
} from '../../../../../common/data/actions';
import {
  COLOUR_BLACK_5,
  COLOUR_BLACK_80,
  COLOUR_PRIMARY,
} from '../../../../shared/colours';
import LoadingSpinner from '../../../UI/LoadingSpinner/LoadingSpinner';
import { CloseIconGrey } from '../../../../shared/libs/Icons';
import EmailModal from './EmailModal/EmailModal';
import WalletModal from './WalletModal/WalletModal';

const NewAuth = (props) => {
  const dispatch = useDispatch();
  const router = useRouter();
  const [emailMode, setEmailMode] = useState(false);
  const [walletMode, setWalletMode] = useState(false);
  const {
    redirect, allowGuest, showCloseIcon = true, closeModal, hideHeader,
  } = props;
  const AuthOption = ({ icon, text, onClick }) => (
    <button
      type="button"
      onClick={onClick}
      aria-label={text}
      className={styles.authOption}
    >
      {icon}
      <p className={styles.title}>{text}</p>
    </button>
  );

  useEffect(() => {
    if (router.pathname === '/') {
      dispatch(resetRedirectState());
    }
    if (router.query.referredBy) {
      localStorage?.setItem('referredBy', router.query.referredBy);
    }
  }, []);

  const lightMode = useSelector((state) => state.main.lightMode);

  const renderLoader = () => (
    <div className="main-loader">
      <div className="main-loader-logo">
        <img src={lightMode ? '/logo.png' : '/logo_dark.png'} alt="Entre" />
      </div>
      <LoadingSpinner />
    </div>
  );

  if (typeof window === 'undefined') {
    return renderLoader();
  }

  const handleGoogleSignIn = () => {
    let red = redirect;
    if (!red && window.location.search) {
      const params = new URLSearchParams(window.location.search);
      if (params) {
        red = params;
      }
    }
    dispatch(googleLogin(red));
  };

  const handleAppleSignIn = () => {
    let red = redirect;
    if (!red && window.location.search) {
      const params = new URLSearchParams(window.location.search);
      if (params) {
        red = params;
      }
    }
    dispatch(appleLogin(red));
  };

  const handleGuestSignIn = (fullName) => {
    if (fullName.length < 3) {
      toast.error('The full name must be at least 3 characters long');
      return;
    }

    let red = redirect;
    if (!red && window.location.search) {
      const params = new URLSearchParams(window.location.search);
      if (params) {
        red = params;
      }
    }
    dispatch(guestLogin(fullName));
  };

  const handleGuestLogin = () => {
    Swal.fire({
      title: 'Full Name:',
      input: 'text',
      background: COLOUR_BLACK_80,
      color: COLOUR_BLACK_5,
      inputAttributes: {
        autocapitalize: 'off',
      },
      showCancelButton: true,
      confirmButtonText: 'Access as a Guest',
      confirmButtonColor: COLOUR_PRIMARY,
      showLoaderOnConfirm: true,
      preConfirm: (fullName) => handleGuestSignIn(fullName),
      allowOutsideClick: () => !Swal.isLoading(),
    });
  };

  const MainComponent = () => (
    <div className={clsx(styles.wrapper, router.pathname !== '/' && styles.bordered)}>
      {!hideHeader ? (
        <header className={styles.header}>
          <p className={styles.headerTitle}>Continue with</p>
          {showCloseIcon ? (
            <button
              className={styles.closeIcon}
              onClick={closeModal}
              type="button"
            >
              {CloseIconGrey}
            </button>
          ) : null}
        </header>
      ) : null}
      <main>
        {/* {supported ?
           <AuthOption text="Deso" onClick={handleDeSoLogin} icon={<DeSoIcon />} /> : null} */}

        {/* shows metamask login even though the user does not have it installed */}

        {/* <AuthOption
        text="Metamask" onClick={handleMetamaskSignIn} icon={<MetamaskIcon />} /> */}
        <AuthOption
          text="Wallet"
          onClick={() => setWalletMode(true)}
          icon={<WalletIcon />}
        />
        <AuthOption
          text="Google"
          onClick={handleGoogleSignIn}
          icon={<GoogleIcon />}
        />
        <AuthOption
          text="Apple"
          onClick={handleAppleSignIn}
          icon={<AppleIcon />}
        />
        <AuthOption
          text="Email"
          onClick={() => setEmailMode(true)}
          icon={<EmailIcon />}
        />
        {allowGuest ? (
          <AuthOption
            text="Access as Guest"
            onClick={handleGuestLogin}
          />
        ) : (
          ''
        )}
      </main>

      <footer className={styles.footer}>
        <p>
          By signing you accept The
          <a className={styles.textLink} target="_blank" href="https://joinentre.com/terms" rel="noreferrer">
            {' '}
            Terms of Services
            {' '}
          </a>
          and
          <a className={styles.textLink} target="_blank" href="https://joinentre.com/privacy" rel="noreferrer">
            {' '}
            Privacy Policy
          </a>
        </p>
      </footer>
    </div>
  );

  return (
    <>
      {emailMode ? (
        <EmailModal
          onClose={() => setEmailMode(false)}
          redirect={redirect}
        />
      ) : null }
      {walletMode ? (
        <WalletModal
          onClose={() => setWalletMode(false)}
          redirect={redirect}
        />
      ) : null}
      {!emailMode && !walletMode ? (
        <MainComponent />
      ) : null}
    </>
  );
};

export default NewAuth;
